import React, { useState } from "react"
import Helmet from "react-helmet"
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'

import Searchbar from "../components/searchbar"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({data}) => {
  const latestPosts = data.allMarkdownRemark.edges.slice(0, 2).map(post => (
    <div key={post.id + post.node.frontmatter.title} className="card">
      <Link to={post.node.frontmatter.path} >
        <div className="card-image">
          <Img fluid={post.node.frontmatter.thumb.childImageSharp.fluid} alt={post.node.frontmatter.title} />
        </div>
        <div className="cardtitle">
          <h2>{post.node.frontmatter.title}</h2>
        </div>
      </Link>
    </div>
   ))

  const initarchive = data.allMarkdownRemark.edges.map(post => (
    <div key={post.id + post.node.frontmatter.title + post.node.frontmatter.date} >
      <Link to={post.node.frontmatter.path}className="archive-post" >
        <div className="archive-image">
          <Img fluid={post.node.frontmatter.thumb.childImageSharp.fluid} alt={post.node.frontmatter.title} />
        </div>
        <div className="archive-title">
          <h2>{post.node.frontmatter.title}</h2>
          <p>{post.node.excerpt}</p>
        </div>
      </Link>
    </div>
  ))

  const [archive, setArchive] = useState(initarchive)

  function SortByTag(e) {
    if (e.target.value === 'all') {
        setArchive(initarchive);
    } else {
      const tagarchive = data.allMarkdownRemark.edges.map(post => {
        if (post.node.frontmatter.tags.includes(e.target.value)) {
          return (
            <div key={post.id + post.node.frontmatter.title + post.node.frontmatter.date} >
              <Link to={post.node.frontmatter.path}className="archive-post" >
                <div className="archive-image">
                  <Img fluid={post.node.frontmatter.thumb.childImageSharp.fluid} alt={post.node.frontmatter.title} />
                </div>
                <div className="archive-title">
                  <h2>{post.node.frontmatter.title}</h2>
                  <p>{post.node.excerpt}</p>
                </div>
              </Link>
            </div>
          )
        }else return null;})
      setArchive(tagarchive)
    }
  }

  return (
    <Layout>
      <Helmet>
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={data.site.siteMetadata.description} />
        <meta property="og:image" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        <link rel="canonical" href={data.site.siteMetadata.siteUrl} />
      </Helmet>
      <SEO title="Home" />
      <div id="topBar">
        <h1>Latest Posts by Darkroom</h1>
        <Searchbar pageQuery={data}/>
      </div>
      
      <div className="latest">
        {latestPosts}
      </div>
      <div id="bottomBar">
        <h1 style={{marginTop:'25px'}}>Darkroom Archive</h1>
        <div>
          <button onClick={SortByTag} value="all">All</button>
          <button onClick={SortByTag} value="tech">Tech</button>
          <button onClick={SortByTag} value="privacy">Privacy</button>
          <button onClick={SortByTag} value="mastery">Mastery</button>
          <button onClick={SortByTag} value="philosophy">Philosophy</button>
        </div>
      </div>
      <div className="archive">
        {archive}
      </div>
    </Layout>
  )
}

export const indexQuery = graphql`
  query index{
    allMarkdownRemark (
      sort: {fields: [frontmatter___date], order: DESC}
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            path
            date (formatString: "MMM Do YYYY")
            thumb{
              childImageSharp{
                fluid(maxWidth: 800, maxHeight: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tags
          }
          excerpt(pruneLength: 280)
        }
      }
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
    site {
      siteMetadata {
        title
        description
        keywords
        siteUrl
      }
    }
  }
`

export default IndexPage
