import React, { useState } from 'react'
import { Link } from "gatsby"
import Img from "gatsby-image"

export default function Searchbar(props) {
    const { pageQuery } = props
    const emptyQuery = ""
    const [state, setState] = useState({
        filteredData: [],
        query: emptyQuery
    })

    function handleChange(e){
        const searchListContainer = document.getElementById('searchList')
        const query = e.target.value
        const posts = pageQuery.allMarkdownRemark.edges || []
        const filteredData = posts.filter(singlePost => {
            const {excerpt} = singlePost.node
            const {title} = singlePost.node.frontmatter
            return (
                excerpt.toLowerCase().includes(query.toLowerCase()) || 
                title.toLowerCase().includes(query.toLowerCase())
            )
        })
        setState({
            query,
            filteredData
        })


        if (e.target.value.length === 0) {
            searchListContainer.style.display = "none"
        } else {
            searchListContainer.style.display = "block"
        }
    }

    const { filteredData } = state
    const postList = filteredData.length ? (
        filteredData.map(post => {
            return (
                <div key={post.id} >
                    <Link to={post.node.frontmatter.path}className="archive-post" >
                            <div className="archive-image">
                                <Img fluid={post.node.frontmatter.thumb.childImageSharp.fluid} alt={post.node.frontmatter.title} />
                            </div>
                            <div className="archive-title">
                                <h2>{post.node.frontmatter.title}</h2>
                                <p>{post.node.excerpt}</p>
                            </div>
                    </Link>
                </div>
            )
        })
    ) : (
        <div id="noResults">
            <p>No results</p>
        </div>
    )

    return (
        <div className="searchRel">
            <div id="searchBar">
                <input type="text" placeholder="Search" onChange={(e) => handleChange(e)}/>
            </div>
            <div id="searchList">
                { postList }
            </div>
        </div>
    )
}
